@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #131315;
  color: #ffff;
  font-family: "Satoshi", sans-serif;
}
@layer components {
  /* Buttons */
  .btnPrimary {
    @apply bg-gradient-to-b from-secondary to-primary rounded cursor-pointer font-medium py-2 text-lg text-center px-6 transition-all text-white;
  }

  .btnPrimary1 {
    @apply bg-gradient-to-b from-purple-600 to-purple-900 rounded cursor-pointer font-medium py-2 text-lg text-center px-6 transition-all text-white;
  }

  .btnSecondary {
    @apply bg-gradient-to-b from-transparent to-white/20 border cursor-pointer rounded font-medium py-2 text-center px-6 transition-all text-white;
  }

  .btnSecondaryShadow {
    @apply bg-gradient-to-b from-transparent to-white/20 border cursor-pointer rounded font-medium py-2 text-center px-6 transition-all text-white shadow shadow-white;
  }

  .btnSecondary5 {
    @apply bg-gradient-to-b from-transparent to-white/20 border cursor-pointer rounded font-medium py-2 text-center px-3 transition-all text-white;
  }

  .btnSecondary2 {
    @apply bg-gradient-to-b from-transparent to-white/20 border cursor-pointer rounded text-xs  text-center transition-all text-white w-12 h-6;
  }

  .btnSecondary3 {
    @apply bg-gradient-to-b from-transparent to-white/20 border cursor-pointer rounded text-sm  text-center transition-all text-white w-28 h-10;
  }

  .btnSecondary4 {
    @apply bg-gradient-to-b from-transparent to-white/20 border cursor-pointer rounded text-sm  text-center transition-all text-white w-12 h-5;
  }

  .btnPrimarySmall {
    @apply bg-gradient-to-br from-secondary to-primary rounded-full text-sm font-medium py-2 text-center px-4 transition-all text-white ;
    white-space: nowrap;
    overflow: hidden;
  }

  .btnSecondary5 {
    @apply bg-gradient-to-b from-secondary to-primary rounded cursor-pointer font-medium py-2 text-sm text-center px-4 transition-all text-white;
  }

  
  /* For viewports smaller than 600px */
@media (max-width: 600px) {
  .btnSecondary, .btnSecondary3 {
    /* Adjust the button size for small screens */
    font-size: 12px;
    padding: 7px;
    width: auto; /* Adjust as needed */
    height: auto; /* Adjust as needed */
  }
}

/* For viewports between 600px and 900px */
@media (min-width: 600px) and (max-width: 900px) {
  .btnSecondary, .btnSecondary3 {
    /* Adjust the button size for medium screens */
    font-size: 12px;
    padding: 9px;
    width: auto; /* Adjust as needed */
    height: auto; /* Adjust as needed */
  }
}

/* For viewports larger than 900px */
@media (min-width: 900px) {
  .btnSecondary, .btnSecondary3 {
    /* Adjust the button size for large screens */
    font-size: 12px;
    padding: 10px;
    width: auto; /* Adjust as needed */
    height: auto; /* Adjust as needed */
  }
}

  .btnPrimary:hover {
    @apply opacity-80;
  }

  /* Headings */
  .heading1 {
    @apply text-4xl font-bold;
  }

  .heading2 {
    @apply text-2xl font-bold;
  }

  .heading {
    @apply text-xl font-bold;
  }

  .socialIcon {
    @apply w-7 h-7;
  }

  .selected {
    transform: scale(1.2);
    border: 2px solid #000;
  }

  .full-container {
    height: 100%;
  }

  .logo {
    width: 100px; /* adjust as needed */
    height: auto;
  }

  .fNav {
    @apply font-medium text-lg;
  }

  /* Inputs */
  .loginFormField {
    @apply px-5 py-3 shadow rounded-md w-full;
  }

  .navLink {
    @apply p-4 text-xl w-full border-b;
  }

  .pageLink {
    @apply p-[13px] rounded-lg flex items-center w-full;
  }
}

.clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

li {
  list-style: none;
}

.active {
  background-color: white;
  color: black;
}

.liner_gradient {
  background: linear-gradient(
    90deg,
    rgba(135, 112, 184, 1) 0%,
    rgba(184, 75, 153, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.loader {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


@keyframes highlight {
  0% { background-color: transparent; }
  50% { background-color: yellow; }
  100% { background-color: transparent; }
}

/* Add this CSS for the Start component */
.bg-start {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* Make the image responsive */
.img-responsive {
  max-width: 100%;
  height: auto;
}

.date, .profit, .ea, .account-id {
  padding: 0.1em;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.bg-glass {
  background: rgba(141, 107, 221, 0.525); /* Increase the opacity for a whiter appearance */
  backdrop-filter: blur(1px); /* Increase the blur for a more frosted glass effect */
  border-radius: 15px;
  border: 1px solid rgba(230, 123, 240, 0.2);
  box-shadow: 0 8px 32px 0 rgba(243, 242, 243, 0.3);
}

.bg-glass-only {
  background: rgba(141, 107, 221, 0.525); /* Increase the opacity for a whiter appearance */
  backdrop-filter: blur(1px); /* Increase the blur for a more frosted glass effect */
  border-radius: 15px;
  border: 1px solid rgba(230, 123, 240, 0.2);
  box-shadow: 0 8px 32px 0 rgba(243, 242, 243, 0.18);
}

.bg-glass2 {
  background: rgba(48, 24, 104, 0.525); /* Increase the opacity for a whiter appearance */
  backdrop-filter: blur(1px); /* Increase the blur for a more frosted glass effect */
  border-radius: 15px;
  border: 1px solid rgba(164, 57, 226, 0.2);
  box-shadow: 0 8px 32px 0 rgba(243, 242, 243, 0.3);
}

.bg-glass1 {
  background: rgba(211, 58, 58, 0.525); /* Increase the opacity for a whiter appearance */
  backdrop-filter: blur(1px); /* Increase the blur for a more frosted glass effect */
  border-radius: 15px;
  border: 1px solid rgba(233, 102, 178, 0.2);
  box-shadow: 0 8px 32px 0 rgba(243, 242, 243, 0.3);
}

.bg-glass3 {
  background: rgba(141, 27, 135, 0.527); /* Increase the opacity for a whiter appearance */
  backdrop-filter: blur(1px); /* Increase the blur for a more frosted glass effect */
  border-radius: 15px;
  border: 1px solid rgba(231, 102, 233, 0.2);
  box-shadow: 0 8px 32px 0 rgba(243, 242, 243, 0.3);
}

.layer1 {
  background: rgba(254, 255, 255, 0.342);
  box-shadow: 0 8px 32px 0 rgba(86, 31, 135, 0.37);
}

.layer2 {
  background: rgba(254, 255, 255, 0.247);
  box-shadow: 0 6px 24px 0 rgba(86, 31, 135, 0.28);
}

.layer3 {
  background: rgba(254, 255, 255, 0.3);
  box-shadow: 0 4px 16px 0 rgba(86, 31, 135, 0.2);
}

.radio-button-group {
  display: flex;
  justify-content: space-between; /* Optional: Adds space between the radio buttons */
}
